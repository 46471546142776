<template>

  <v-container>
    <v-row v-for="(service, i) in toVenueBuses"
             :key="i">
      <v-col lg="12" md="12" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <h3>{{service.name}}</h3>
          </div>
        </div>
      </v-col>
      <v-col md="3"
             sm="6"
             cols="12"
             class="mt--30"
             >
        <div class="standard-service  super-center text-center ">
          <div class="content super-center">
            <ul>
              <div 
                v-for="(person, l) in service.group1"
                :key="l">
                {{person}}
              </div>
            </ul>
          </div>
        </div>
      </v-col>
      <v-col md="3"
             sm="6"
             cols="12"
             class="mt--30"
             >
        <div class="standard-service  super-center text-center ">
          <div class="content super-center">
            <ul>
              <div
                v-for="(person, l) in service.group2"
                :key="l">
                {{person}}
              </div>
            </ul>
          </div>
        </div>
      </v-col>
      <v-col md="3"
             sm="6"
             cols="12"
             class="mt--30"
             >
        <div class="standard-service  super-center text-center ">
          <div class="content super-center">
            <ul>
              <div
                v-for="(person, l) in service.group3"
                :key="l">
                {{person}}
              </div>
            </ul>
          </div>
        </div>
      </v-col>
      <v-col md="3"
             sm="6"
             cols="12"
             class="mt--30"
             >
        <div class="standard-service  super-center text-center ">
          <div class="content super-center">
            <ul>
              <div
                v-for="(person, l) in service.group4"
                :key="l">
                {{person}}
              </div>
            </ul>
          </div>
        </div>
      </v-col>
      <v-col v-for="(stop, s) in service.stops"
       :key="s" :lg="stopSpacing(service.stops)" :md="stopSpacing(service.stops)" cols="12"  class="mt--30">
        <div class="super-center">
          <b class="time-displays">
            {{stop.location}} <br/> Pick up - {{stop.arrival}} <br /> Departing - {{stop.departure}}
          </b>
          <a :href="stop.link" class="super-center">
            <img :src="stop.src" class="img-center removeDisplay" />
          </a>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  export default {
  data() {
  return {
  toVenueBuses: [
  {
  name: "Bus 1 - R Hotel & Quest Geelong",
  stops: [
  {src: require("../../assets/images/shuttle/bus1-2.png"), link: "https://maps.app.goo.gl/diR2YEwmjr4r6QNW8", arrival: "2:20pm", departure: "2:40pm", location:"R Hotel"},
  {src: require("../../assets/images/shuttle/bus1-1.png"), link: "https://maps.app.goo.gl/qAArGvGFdVdVotLx6", arrival: "2:50pm", departure: "3:10pm", location:"Quest Geelong (Esplanade)"},
  ],
  group1: [
  'Aaron',
  'Ada',
  'Bamboo',
  'Betty',
  'Billy',
  'Cameron',
  'Carol',
  'Cody',
  'Daniel',
  'Dean T',
  'Debbie',
  ],
  group2: [
  'Dianne',
  'Emmy',
  'Geoff',
  'Harry',
  'Jack',
  'Jackson',
  'Jada',
  'Jamie',
  'Jed',
  'Jo',
  'Josh Ma',
  ],
  group3: [
  'Judit',
  'Judy',
  'Kathy',
  'Kim',
  'Kirraly',
  'Kylie',
  'Lara',
  'Lisa',
  'Mark H',
  'Mark R',
  ],
  group4: [
  'Marli',
  'Massey',
  'Mitchell H',
  'Rhiain',
  'Riley',
  'Scott M',
  'Sophie',
  'Tony',
  'Zoe'],
  },
  {
  name: "Bus 2 - Geelong Railway Station",
  src: require("../../assets/images/things/beavs.jpeg"),
  stops: [
  {src: require("../../assets/images/shuttle/bus2-1.png"), link: "https://maps.app.goo.gl/AswLGKrBj8Nx2Ryt9" , arrival: "2:40pm", departure: "3:00pm", location:"Geelong Railway Station"}
  ],
  group1: [
  'Abby',
  'Alister',
  'Alvin',
  'Amanda',
  'Ash P',
  'Ben',
  'Beth',
  'Bill',
  'Chad',
  'Chris S',
  ],
  group2: [
  'Cole',
  'Craig',
  'Dale',
  'Ebony',
  'Emma R',
  'Ian A',
  'Jimmy',
  'Jane',
  'Jazz',
  'Jonty',
  ],
  group3: [
  'Jordan',
  'Kaye',
  'Keely',
  'Lexii',
  'Miyi',
  'Nazz',
  'Neil',
  'Paul D',
  'Rachel',
  ],
  group4: [

  'Ross',
  'Simon',
  'Steph',
  'Steve S',
  'Tom Sh',
  'Tom St',
  'Tracey',
  'Zac',
  'Zarli'],
  }
  ],
  };
  },
  methods: {
  stopSpacing (array) {
  return 12 / array.length
  }
  },
  };

</script>


<style lang="scss">
  .super-center {
  text-align: center;
  }

  .removeDisplay {
  display: normal !important;
  }

  .img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  }

  .time-displays {
    font-size: 20px;
  }
</style>
